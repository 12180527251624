/* src/components/CarouselSkeleton.css */

.carousel-skeleton {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    border-radius: 8px;
    background-color: #f0f0f0;
    width: 100%;
    height: 400px; /* Adjust height as needed */
}

.skeleton-image {
    width: 100%;
    height: 100%;
    background-color: #ccc;
    border-radius: 4px;
}

.skeleton-text {
    width: 40%;
    height: 10%;
    background-color: #ddd;
    margin-top: 20px;
    border-radius: 4px;
}

.skeleton-button {
    width: 30%;
    height: 20%;
    background-color: #ddd;
    margin-top: 10px;
    border-radius: 20px;
}