.pack-product{
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 15px;
    margin-top: 15px;
}

.pack-pro-heading{
    padding: 18px 0px 15px 0px;
    font-size: 21px;
}

.pack-pro-img{
    max-width: 300px;
    height: auto;
}
.pack-pro-price{
    text-align: center;

}

.fgjchwewe{
    padding: 20px;
    gap: 10px;
    display: flex;
    justify-content: space-between;
}

 .fgjchwewe button.hrntbgrf{
    border-radius: 5px;
    margin-right: 5px;
    background-color: #fff;
    border: none;
    border: 1px solid #ff00ed;
    color: #ff00ed;
    padding: 7px 25px 7px 25px;;
}

.brgvfcsx{
    border-radius: 5px;
    color: #fff;
    border: none;
    background-color: #ff00ed;
    padding: 8px 20px 8px 20px !important;
} 

.pack-price{
    font-size: 18px;
    font-weight: 600;
}

.package-pro-desc{
    padding: 20px;
    color: #000;
    font-size: 13px;
    font-weight: 500;
    min-height: 6em;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.pack-product-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: .2rem 1rem;
    border-radius: 5px;
    background-color: #d6e2f8;
}

.product_info{
    display: flex;
    align-items: center;
}

.porductlist{
    
    height: 302px;
    max-height: 302px;
    overflow-y: scroll;
    /* background-color: aqua; */
}

.pack-product_info{
    display: flex;
    padding: 10px 0px 10px 0px;
    align-items: center;
}

.pack-product-name{
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.product-style{
    display: flex;
    justify-content: center;
    align-items: center;
}

.no_product{
    color: gray !important;
    font-size: 14px !important;
}

.no-product{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 88vh; /* Adjust based on your layout */
    font-size: 20px; /* Adjust the font size as needed */
    color: #333; /* Adjust the color as needed */
    text-align: center;
}

@media(max-width: 768px){
    .fgjchwewe{
        display: flex;
        justify-content: center;
    }

  .fgjchwewe .hrntbgrf{
    font-size: 10px;
  }

  .fgjchwewe .brgvfcsx{
    padding: 7px 25px 7px 25px;
    font-size: 10px;
  }
  .pack-product-name{
    color: #000;
    font-size: 12px;
    font-weight: 500;
}
}