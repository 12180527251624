.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: none !important;
}

.card:hover{
    box-shadow: none !important;
}

.text-primary {
    color: #007bff !important;
}

.btn-block {
    width: 100%;
}

.btn-block a:hover{
    color: #fff !important;
}
.common_img{
    width: 75px;
    height: auto;
}

.costp p{
    color: #535353;
}