
.package_content{
    margin-top: 60px;
}

.package_para{
    margin-top: 50px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.package_heading{
    text-align: center;
    margin: 30px 60px;
}


.package-img img{
    width: 200px;
    height: 150px;
    border-radius: 20px;
}
.package-img .package-text{
    text-align: center;
    position: relative;
    bottom: 25px;
    overflow: hidden;
}

.package-text{
    width: 200px;
    height: auto;
    font-size: 16px;
    color: #fff;
    overflow: hidden;
    font-weight: 600;
}

@media (max-width: 768px ){
    .tab-menu{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        gap: 15px;
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .package-img img {
        width: 100px;
        height: 90px;
        border-radius: 20px;
    }

    .package-img .package-text{
        text-align: center;
        position: relative;
        bottom: 25px;
        overflow: hidden;
        right: 50px;
    }

    .package-text{
        width: 200px;
        height: auto;
        font-size: 12px;
        color: #fff;
        overflow: hidden;
        font-weight: 600;
    }

    .package_heading{
        text-align: center;
        margin: 25px 11px;
    }
    .package_heading h1{
        font-size: 16px !important;
    }
}