.agreement {
    padding: 10px;
    max-width: 1000px;
    margin: 20px auto;
    line-height: 1.6;
    max-width: 1000px;
  }
  
  .agtitle {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  
  .agreement h2 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
  }
  
  .warning {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
  }