@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

/* Header Top Strip  */
.header-top-strip {
    background-color: #ffffff;
    border-bottom: 1px solid var(--color-3b4149);
}

.header-top-strip p,
a {
    font-size: 1rem;
}

/* Header Middle Start  */
.header-middle {
    background-color: #ffffff;
    padding: 15px 0px 15px 0px;
}

.header-middle .logo img {
    width: 250px;
    height: auto;
    object-fit: contain;
}

.logo_item {
    display: flex;
    justify-content: center;
}

.search_form {
    width: 100%;
    justify-content: center;
}

.common_icon_c {
    color: #000;
    gap: 30px;
    font-size: 22px;
    margin-left: 5px;
}

.common_icon_cc {
    display: flex;
    align-items: center;
    color: #092143;
    font-size: 19px;
    gap: 5px;
}

.common_icon_cc p {
    color: #092143;
    font-size: 13px;
    font-weight: 700;
    font-style: normal;
    white-space: nowrap;
    text-transform: uppercase;
}

.new_common_head {
    font-size: 13px;
    font-weight: 700;
    font-family: "Lato", Arial, Helvetica, sans-serif;
    white-space: nowrap;
    font-style: normal;
}

.pro_icon {
    font-size: 19 !important;
    gap: 10px;
    margin-right: 10px;
}

.login_section {
    display: flex;
    align-items: center;
    gap: 25px;
    color: #092143;
}

.input_radius {
    border-top-left-radius: 21px;
    border-bottom-left-radius: 21px;
    padding-top: 7px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-bottom: 7px;
    width: 50%;
    height: 36px;
}

.select_option {
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 0px;
    height: 45px;
    width: 170px;
    font-size: 16px;
    color: gray;
}

.search_button {
    position: relative;
    float: right;
    border-radius: 0px 21px 21px 0px;
    background-color: #081ee9;
    color: #fff;
    border: none;
    padding: 7px 16px;
    height: 36px;
}

/* Header Bottom Start  */


.dwdf{
    list-style: none;
}
.header-bottom {
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(11px);
}

.header-bottom .dropdown img {
    width: 30px;
    height: 30px;
    object-fit: contain;
}

.header-bottom .menu-links a,
.header-bottom .dropdown button {
    color: #000 !important;
    font-size: 13px;
    line-height: 18px;
    font-weight: 700;
    font-family: "Lato", Arial, Helvetica, sans-serif;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    border: none;
}

.header-bottom .dropdown button:focus {
    box-shadow: none;
}

.header-bottom .dropdown-menu {
    /* background-color: var(--color-C22026); */
    background-color: white;
    width: 95% !important;
    border-radius: 0px !important;
    transform: translate3d(0px, 57px, 0px) !important;
    padding: 0;
}

.header-bottom .dropdown-menu .dropdown-item {
    border-bottom: 1px solid rgb(185, 74, 74) !important;
    color: black !important;
    margin-bottom: 0px;
    padding: 15px 20px !important;
}

.header-bottom .dropdown-menu .dropdown-item:hover {
    /* background-color: transparent !important; */
    color: white !important;
    background-color: var(--color-C22026);
    border-bottom: 1px solid white !important;
}

.menu-links ul {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
    margin: 0 !important;
    list-style: none;
    width: 100%;
}


.sticky__header {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 999;
    box-shadow: 3px 3px 8px -3px #ddd;
    background-color: var(--secondary-color);
}

.nav__item a {
    color: var(--primary-color);
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
}
.nav__active {
    font-weight: 700 !important;
}


.srcitem {
    border-radius: 10px;
    z-index: 1000;
    background: rgb(238, 237, 237);
    position: absolute;
    width: 95%;
    top: 47px;
    font-family: "Montserrat", sans-serif;
    padding: 10px 20px;
}

.srcItem {
    cursor: pointer;
}

.srcItem:hover {
    color: #c22026;
    font-weight: 500;
}

.linkst {
    display: block;
}

.header-bottom .dropdown-menu {
    background-color: white;
    width: 95% !important;
    border-radius: 0px !important;
    transform: translate3d(0px, 57px, 0px) !important;
    padding: 0;
    z-index: 9000;
}

.header_top_home {
    border-color: #3a4d6942;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    color: white;
    align-items: center;
    max-width: 100%;
    margin: 0px !important;
}

.header_item {
    border-color: rgba(58, 77, 105, 1);
    border-right-width: 1px;
    border-right-style: solid;
    padding: 0px 0px 0px 5px;
    color: #092143;
}

.header_item2 {
    border-color: rgba(58, 77, 105, 1);
    border-right-width: 1px;
    border-right-style: solid;
    padding: 3px 0px 3px 5px;
    color: #092143;
}

.header_item3 {
    color: #092143;
}

.header_top_icon {
    border-color: rgba(58, 77, 105, 1);
    border-right-width: 1px;
    border-right-style: solid;
    padding: 5px;
    font-size: 15px;
}

.textitem {
    display: flex;
    align-items: center;
    font-size: 12px !important;
    font-weight: 400;
    color: #092143;
    margin-left: 5px;
    letter-spacing: 1px;
    font-family: "Lato", Arial, Helvetica, sans-serif;
}

.header_icon {
    font-size: 16px;
}

.search-field {
    position: relative;
    width: 100%;
    justify-content: center;
}

.menu-slide {
    position: fixed;
    top: 0;
    right: -50%; 
    width: 50%;
    height: 100%;
    background-color: white; 
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transition: right 0.3s ease;
    z-index: 1000;
    overflow-y: auto;
  }
  
  .menu-slide.open {
    right: 0; /* Slide in */
  }
  
  .menu-toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .menu-toggle-icon {
    display: none;
    margin-left: auto;
    cursor: pointer;
  }
  
  .menu-links {
    display: flex;
    align-items: center;
  }
  
  .menu-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  

@media (max-width:1280px) and (min-width:700px) {
    .common_icon_c {
        color: #092143;
        gap: 30px;
        font-size: 17px;
        margin-left: 5px;
    }

    .login_section {
        display: flex;
        gap: 13px;
        color: #092143;
    }

    .common_icon_cc {
        color: #092143;
        gap: 5px;
        font-size: 19px;
    }

    .header_top_icon {
        border-right: 2px solid #B2BABB;
        padding: 3px;
        font-size: 12px;
    }

    .textitem {
        display: flex;
        align-items: center;
        font-size: 8px !important;
        color: #fff;
        margin-left: 5px;
    }

    .input_radius {
        border-top-left-radius: 21px;
        border-bottom-left-radius: 21px;
        padding-top: 7px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        padding-bottom: 7px;
        width: 100%;
        height: 35px;
    }

    .select_option {
        padding-top: 7px;
        padding-bottom: 7px;
        border-radius: 0px;
        height: 35px;
        width: 170px;
        font-size: 16px;
        color: gray;
    }

    .search_button {
        position: relative;
        float: right;
        left: -20px;
        border-radius: 0px 21px 21px 0px;
        background-color: #ff00ed;
        color: #fff;
        border: none;
        padding: 11px 22px;
        height: 35px;
    }
   
}


/* Header Part End Here  */

@media (max-width: 991.98px) {
    .header-middle {
        padding: 0px;
    }

    .header-middle .logo img {
        width: 270px;
        height: auto;
        object-fit: contain;
    }

    .header-middle .search-group input {
        width: 80%;
    }

    .header-middle .search-icon {
        width: 20%;
    }

    .header-middle .header-middle-links img {
        width: 25px;
        height: 25px;
        object-fit: contain;
    }

    .header-middle-links .wishlist p,
    .my__account p {
        display: none;
    }

    .header-bottom .dropdown img {
        width: 20px;
        height: 20px;
        object-fit: contain;
    }

    .header-bottom .menu-links a,
    .header-bottom .dropdown button {
        color: white;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        letter-spacing: 0.3px;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 5px;
        border: none;
    }

    .logo_item {
        display: flex;
        justify-content: flex-start;
    }

    .logo_item {
        width: 20%;
    }

    .search-field {
        width: 100%;
    }

    .search_button {
        position: relative;
        float: right;
        /* left: -20px; */
        border-radius: 0px 21px 21px 0px;
        background-color: #ff00ed;
        color: #fff;
        border: none;
        padding: 2px 8px;
        height: 27px;
    }

    .select_option {
        width: 100px;
        height: 27px;
        width: 30px;
    }

    .input_radius {
        font-size: 10px;
        border-top-left-radius: 21px;
        border-bottom-left-radius: 21px;
        padding-top: 7px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        padding-bottom: 7px;
        width: 100%;
        height: 27px;
    }

    .search_bar {
        display: flex;
        justify-content: flex-end;
    }
    .menu-toggle-icon {
        display: block;
      }

      .menu-links ul {
        flex-direction: column;
      }
      
}

@media (max-width: 575.98px) {
    /* Header Top Strip  */

    .header-top-strip p,
    a {
        font-size: 0.9rem;
    }

    .header_top_home {
        display: none;
    }

    /* Header Middle Start  */
    /* .logo_item{
        width: 20%;
    } */
    .header-middle .logo {
        text-align: center;
        padding-bottom: 10px;
    }

    .header-middle .logo img {
        width: 110px;
        height: auto;
        object-fit: contain;
    }

    .header-middle .search-group {
        width: 100%;
    }

    .header-middle .search-group input {
        width: 80%;
    }

    .header-middle .search-icon {
        width: 20%;
    }

    .header-middle .header-middle-links {
        padding: 15px 0px 10px 0px;
    }

    .header-middle-links .wishlist p,
    .my__account p {
        display: block;
    }

    .header-middle .header-middle-links img {
        width: 20px;
        height: 20px;
    }

    .input_radius {
        display: flex;
        justify-content: center;
        margin-left: 45px;
    }

    .select_option {
        width: 100px;
        height: 27px;
        width: 30px;
    }

    .search_bar {
        display: flex;
        justify-content: flex-end !important;
        width: 80%;
    }

    .logo_item {
        padding-top: 10px;
        padding-bottom: 10px;
        width: 20%;
    }

    /* Header Bottom Start  */
    .header-bottom dropdown {
        padding-bottom: 20px;
    }

    .menu-links {
        display: none;
    }

    .nav__item a {
        color: var(--primary-color);
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
    }

}


.categoryimg {
    -webkit-filter: grayscale(100%);
    /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

.userst {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.username {
    color: white !important;
    font-size: 13px !important;
    font-weight: 600 ! important;
    border-radius: none ! important;
    /* padding: 11px !important; */
}

.custopdropdown {
    border-left: 1px solid rgba(58, 77, 105, 1);
    border-right: 1px solid rgba(58, 77, 105, 1);
    border-color: rgba(58, 77, 105, 1);
    
}

.userimg {
    color: white;
    font-weight: 700;
    font-size: 21px;
}

.userimgdiv {
    height: 29px;
    width: 33px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #bbb7b7;
}

.userdropicon {
    margin-left: 4px;
    margin-top: -4px;
    color: #092143;
}

.wish_cart {
    display: flex;
    /* justify-content: flex-end; */
}

.wishlist {
    margin-right: 23px;
}

.categoryimgdown {
    /* filter: grayscale(100%); */
    height: 17px;
    width: 17px;
    margin-right: 16px;
    /* opacity: 0.6; */
}

.drpMenuItem {
    position: relative;
    /* background-color: red; */
}

.subcategory__list__st {
    position: absolute;
    right: -301px;
    top: -1px;
    background: rgb(255 255 255);
    width: 300px;
    padding: 5px 1px;
    border-radius: 2px;
    height: 100.2%;
    border: 1px solid #d1cdcd;
}


.css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {

    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    /* box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12); */
    box-shadow: none !important;
    border: 1px solid rgb(197, 197, 197);
    max-height: calc(100% - 96px);
    -webkit-overflow-scrolling: touch;
    position: absolute;
    overflow: inherit !important;
    min-width: 16px;
    min-height: 16px;
    max-width: calc(100% - 32px);
    max-height: calc(100% - 32px);
    outline: 0;
}

.itemstyle--set {
    padding: 8px;
    font-size: 13px !important;
}

.itemstyle--set:hover {
    background-color: var(--color-C22026) !important;
    color: rgb(255, 255, 255)
}

.headerIcondesign {
    font-size: 35px;
    font-weight: 400;
}

span.badge.bg-white.text-dark.cardamount {
    border-radius: 50%;
    height: 22px;
    width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .userst {
        display: none;
    }

    .wish_cart {
        display: none;
    }

    .categories__card {
        display: none;
    }

    .custopdropdown {
        border-left: none;
        border-right: none;
    }

    .features-wrap .single-feature {
        position: relative;
        flex: 1 0 49%;
        max-width: 50%;
        margin-top: 30px;
        padding: 0 15px;
    }

    .cartdropwidth>MuiList-root MuiList-padding MuiMenu-list css-6hp17o-MuiList-root-MuiMenu-list {
        width: 400px !important;
    }
}

.header_item {
    width: 10%;
    text-align: center;
    align-content: center;
    align-items: center;
}
.price_value{
    color: #000;
    font-size: 13px;
}

.empty_cart{
    background-image: url('../../assets/images/emptyCart.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 300px;
    height: 300px;
}
.profile_images{
    width: 25px;
    height: 25px;
    border-radius: 100%;
}
.details_bar{
    background-color:#00cfff;
    color:#fff;
    padding: 8px;
    border-radius: 10px;
}
.header_mail>a:hover{
color: #fff;
}