 p {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
}

 .carousel-caption {
    text-align: left !important;
    width: 50% !important;
    top: 15%;
    left: 12%;
}
#captions_name{
    top:-0%;
}

 .carousel_heading {
    position: absolute;  
}

 .sub_heading {
    position: absolute;
    top: 45%; 
    width: 100%;
    color: #fff;
    font-size: 24px;
    margin-left: 12px;
}
 .carousel-caption1 {
    text-align: left !important;
    width: 50% !important; 
    top: 10%;
    right: 10%; 
    left: auto !important; 
}

 .carousel_heading_1{
    position: absolute;
    /* left: 0px;
    width: 90%; */
    font-size: 2.5rem;
    font-weight: 800;
    width: 100%;
}

 .sub_heading_1{
    position: absolute;
    top: 40%;
    left: 0px;
    color: #fff;
}

 .main_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

 .sdkjhcskd {
    height: 70vh;
    object-fit: cover;
}

.accordion-header button {
    background: transparent !important;
}

@media (max-width: 767.98px) {
    .carousel_heading {
        position: absolute; 
        font-size: 18px !important;
    }

    /* .carousel_caption{
         height: fit-content !important; 
    } */
    .list_item {
        font-size: 8px !important;
        margin: 1px !important;

    }

    .carousel-caption {
        text-align: left !important;
        width: 50% !important;
        top: 5%;
        left: 12%;
    }

    .carousel-caption1 {
        text-align: left !important;
        width: 50% !important; 
        top: 6%;
        right: 11%; 
        left: auto !important; 
      }

      .carousel_heading_1{
        position: absolute;
        left: 15px;
        width: 100%;
        font-size: 18px;
        font-weight: 800;
      }

    
    .sdkjhcskd {
        height: 34vh !important;
        object-fit: cover;
    }

    #new_button_click {
        margin-top: 0px !important;
        border-radius: 15px !important;
        font-size: 12px !important;
        padding: 3px 10px !important;
        margin-top: 10px !important;
    }

}

#new_button_click {
    padding: 9px 37px;
    border-radius: 50px;
    background: #00cfff;
    color: #fff;
    border: none;
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
    box-shadow: rgba(4, 203, 245, 0.25) 0px 30px 60px -12px inset, rgb(4, 150, 150) 0px 18px 36px -18px inset;
    position: absolute;
    top: 80%;

}
#new_button_click_1 {
    padding: 9px 37px ;
    border-radius: 50px ;
    background: #081ee9 ;
    color: #fff ;
    border: none ;
    font-size: 16px ;
    font-weight: 600 ;
    position: relative ;
    top: 87% ;
}

#new_button_click:hover,#new_button_click_1:hover{
    background: #ff00ed; 
}

.custom-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
}

.custom-modal {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 50%;
    max-width: 500px;
}

.custom-modal-close {
    float: right;
    font-size: 1.5rem;
    border: none;
    background: none;
    cursor: pointer;
}

.button_next {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    margin-top: 2rem;
}

.confirm_container {
    background: #D9D9D9;
    padding: 30px;
    /* margin: 0px 50px; */
}

.technician_p {
    color: #535353 !important;
    padding-bottom: 3rem;
}

.tech_labour {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dis_label {
    font-size: 12px;
    color: #535353;
}

@media screen and (max-width: 690px) {
    p {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
    }
    .sub_heading {
        position: absolute;
        top: 43%; 
        width: 100%;
        color: #fff;
        font-size: 14px;
        margin-left: 10px;
    }
    .sub_heading_1{
        position: absolute;
        top: 45%;
        right: 0px;
        color: #fff;
        font-size: 14px !important;
    }
    #new_button_click_1 {
        padding: 3px 10px;
        border-radius: 50px ;
        background: #00cfff ;
        color: #fff ;
        border: none ;
        font-size: 12px ;
        font-weight: 600 ;
        position: relative ;
        top: 90% ;
    }
        .button_next button {
        gap: 15px !important;
        padding: 7px 35px !important;
        display: flex;
    }

    .button_group {
        display: grid !important;
    }

    .custom_tech {
        display: grid !important;
    }

    .dis_label {
        display: block !important;
        /* Ensures the label takes the full width available */
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .confirm_container {
        padding: 10px !important;
    }

    .technician_p {
        padding-bottom: 1rem !important;
    }

    .common_tech {
        padding-bottom: 10px !important;
    }

    .mainh2_sec {
        font-size: 1.5rem;
        padding-bottom: 15px;
    }

    .technician_head {
        font-weight: 700;
        font-size: 24px;
        color: #646464;
    }

    .thubnail_setting {
        height: 200px !important;
    }

    .col-4.review_col-4 {
        position: relative !important;
    }

    .new_row {
        flex-direction: column !important;
    }

    .new_row .col-8 {
        width: 100% !important;
    }

    .new_row .col-4 {
        width: 100% !important;
    }

    .new_row h1 {
        font-size: 1.35rem;
        font-weight: 600;
    }

    .full_card .row {
        padding: 1.25rem;
    }
}

.technician_head {
    font-weight: 700;
    color: #646464;
}

.thubnail_setting {
    background: url(../assets/images/thubnail1.png);
    height: 300px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.thubnail_setting h1,
nav,
ol,
li.breadcrumb-item.active {
    color: #fff;
    font-weight: bold;
}

.setting_new_tech {
    display: grid;
    align-items: center;
    justify-content: center;
}

.col-4.review_col_4 {
    position: absolute;
    top: 0 !important;
    right: 0 !important;
}

.image-wrapper {
    position: relative;
}

.image-wrapper img {
    width: 100%;
    /* display: block; */
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.40);
    /* Adjust the alpha value for lighter or darker overlay */
}
.new_carousal_button{
        justify-content: space-between;
            display: flex;
            width: 65%;
            margin: 0px auto;
            position: relative;
            top: -80px;
            
}
