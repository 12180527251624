.event_main{
    margin-top: 50px;
}

.event_heading{
    margin-top: 50px;
    font-weight: 700;
}

.event_date{
    width: 100%;
    color: #306fdb;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.event_retail_price{
    width: 100%;
    margin-bottom: .5rem;
    font-size: 1.2rem;
    font-weight: 500;
}

.event_retail_price span{
    font-weight: 700;
}

.event_labor_price{
    width: 100%;
    margin-bottom: .5rem;
    font-size: 1.2rem;
    font-weight: 500;
}

.event_labor_price span{
    font-weight: 700;
}

.hgbrvfdcs{
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}

.event_img img{
    height: 490px;
    border-radius: 15px;
}

.event_img_list{
    margin: 8px 15px 8px 0px;
    border-radius: 4px;
    width: 18%;
    height: auto;
}

.event_img_list img{
    border-radius: 14px;
}

.productImage{
    object-fit: cover;
    cursor: pointer;
    transition: 0.4s ease;
}

.productImage:hover{
    transform: scale(1.1);
}
.event_des_heading{
    display: flex;
    justify-content: center;
    margin-top: 5rem;
}

.event_des_para{
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin-top: 2rem;
}
.event_des_team{
    margin-top: 2rem;
    font-size: 18px;
    font-weight: 600;
}



.event_team img{
    width: 5rem;
    border-radius: 50px;
    margin: 5px;
    border: 1px solid #e0d8d8;
}

.product_item{
    margin-bottom: 20px;
}

.event_item{
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 20px;
}

.item_heading{
    font-size: 2rem;
    font-weight: 600;
}

.event_product{
    margin-top: 1rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 6px 10px;
    border-radius: 20px;
}

.event_pro{
    display: flex;
}

.event_pro_img{
    width: 8rem;
    border-radius: 18px;
}

.event_pro_info{
    margin-left: 2rem;
    margin-top: 10px;
}
.event_pro_heading{
    width: 100%;
}
.event_pro_para{
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.event_pro_price{
    padding-top: 15px;
    display: flex;
    justify-content: flex-end;
}

.rental_price{
    float: right;
    font-weight: 600;
}

.delivery_info{
    float: right;
}

.event_btn{
    float: right;
}

.event_btn_devilery{
    /* border: 1px solid #ff00ed;  */
    border: none;
    border-radius: 3px;
    background-color: #ff00ed;
    color: #fff;
    margin-top: 10px;
    margin-right: 7px;
    padding: 5px 10px 5px 10px;
}

@media(max-width: 768px) {
    .event_img img{
        height: 290px;
        border-radius: 15px;
    }
}