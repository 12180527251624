.login-feture {
    background-color: #ffffff;
}

.auth__form {
    width: 32%;
    padding: 40px;
    margin: 0 auto;
    border-radius: 0px;
    box-shadow: 0px 0px 1px 0px;
}

.form__group>.form-control {
    width: 100%;
    color: #212529;
    background: #FFFFFF;
    border-radius: 0px;
    border: 1px solid #0000003b !important;
}

.auth__btn {
    width: 100%;
    background: var(--color-C22026) !important;
    color: var(--secondary-color) !important;
    border-radius: 0px;
    font-weight: 600 !important;
    border: 0;
    padding: 5px;
}

.form__group input[type="file"] {
    cursor: pointer;
    color: var(--secondary-color);
    margin-right: 190px !important;
    border-radius: 5px !important;
}


@media only screen and (max-width: 576px) {
    .auth__form {
        width: 50%;
        margin: 0 auto;
        padding: 40px;
        border-radius: 0px;
        box-shadow: 0px 0px 1px 0px;
    }
}

@media only screen and (max-width: 992px) {
    .auth__form {
        width: 80%;
        margin: 0 auto;
        padding: 40px;
        border-radius: 0px;
        box-shadow: 0px 0px 1px 0px;
    }
}


/* Login CSS */

/* Full-page background with image and form side-by-side */
.login-page {
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Arial', sans-serif;
}

.login-container {
    display: flex;
    width: 80%;
    max-width: 1200px;
    height: 65vh;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
    overflow: hidden;
}

.login-image-container {
    flex: 1;
    background-color: #e6e6e6;
}

.login-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.login-form-container {
    flex: 1;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
}


.login-glass-effect {
    border-radius: 20px;
    padding: 40px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    text-align: center;
    width: 100%;
    max-width: 400px;
}

.login-glass-effect h2 {
    margin-bottom: 30px;
    color: #092143;
    font-size: 24px;
    line-height: 18px;
    font-weight: 700;
    font-family: "Lato", Arial, Helvetica, sans-serif;
}

/* Input fields */
.input-container {
    margin-bottom: 20px;
}

.input-container input {
    width: 100%;
    height: 48px;
    padding: 12px;
    border: none;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    color: #092143;
    font-size: 16px;
    outline: none;
    box-shadow: inset 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

/* input:focus {
    background: rgba(255, 255, 255, 0.4);
} */

/* Login button with glassy effect */
.login-btn {
    background: rgb(255 0 237);
    border: none;
    padding: 10px;
    width: 100%;
    border-radius: 113px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.login-btn:hover {
    background: #00cfe9;
    color: #fff;
}

/* Responsive design */
@media (max-width: 768px) {
    .login-container {
        flex-direction: column;
        height: auto;
        width: 100%;
    }

    .login-image-container {
        height: 300px;
        display: none !important;
    }

    .login-form-container {
        padding: 20px;
    }
}

.welcome-text {
    margin-top: 1rem;
}

.welcome-text p {
    color: #092143;
    font-size: 16px;
    font-weight: bold;
}


/* Signup CSS */


/* Full-page background with image and form side-by-side */
.signup-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Arial', sans-serif;
}

.signup-container {
    display: flex;
    width: 100%;
    max-width: 1200px;
    height: 80vh;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    overflow: hidden;
    margin: 0;
    /* No outer margin */
    padding: 0;
    /* No inner padding */
}

.signup-form-container {
    /* flex: 1; */
    display: flex;
    width: 400px;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    padding: 0;
    /* No padding */
    margin: 0;
    /* No margin */
}

.signup-glass-effect {
    /* background: rgba(255, 255, 255, 0.3); */
    width: 100%;
    padding: 40px;
    /* box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37); */
    /* backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    text-align: center;
    width: 100%;
    max-width: 400px; */
}

.signup-glass-effect h2 {
    margin-bottom: 30px;
    color: #092143;
    font-size: 24px;
    font-weight: 600;
}

.input-container {
    margin-bottom: 20px;
}

.input-container input {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    font-size: 16px;
    outline: none;
    box-shadow: inset 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

input:focus {
    background: rgba(255, 255, 255, 0.4);
}

.signup-btn {
    background: #ff00ed;
    border: none;
    padding: 12px;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.signup-btn:hover {
    background: #00cfe9;
    color: #fff;
}

/* New options below the form */
.signup-options {
    margin-top: 20px;
    font-size: 14px;
    color: #092143;
}

.signup-options p {
    color: #092143;
    font-size: 16px;
    font-weight: 600;
}

.signup-options a {
    text-decoration: none;
    color: #092143;
    transition: color 0.3s ease;
}

.signup-options a:hover {
    color: #092143;
}

/* Image container for the signup page */
.signup-image-container {
    flex: 1;
    margin: 0;
    /* No margin */
    padding: 0;
    /* No padding */
    background-color: #e6e6e6;
}

.signup-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Mobile responsiveness for screens smaller than 768px */
@media (max-width: 768px) {
    .signup-container {
        flex-direction: column;
        height: auto;
    }

    .signup-form-container,
    .signup-image-container {
        width: 100%;
    }

    .signup-form-container {
        padding: 20px;
        order: 1;
        /* Ensure the form comes first on mobile */
    }

    .signup-image-container {
        height: 250px;
        order: 2;
        display: none !important;
    }

    .signup-image {
        height: 100%;
        object-fit: cover;
    }

    .signup-glass-effect {
        padding: 20px;
        max-width: 100%;
    }

    h2 {
        font-size: 24px;
    }

    input {
        font-size: 14px;
        padding: 10px;
    }

    .signup-btn {
        font-size: 14px;
        padding: 10px;
    }

    .signup-options {
        font-size: 12px;
    }
}

/* Small screens like mobile phones (max-width 480px) */
@media (max-width: 480px) {
    .signup-container {
        height: auto;
        flex-direction: column;
    }

    .signup-image-container {
        height: 200px;
    }

    h2 {
        font-size: 22px;
    }

    input {
        font-size: 12px;
        padding: 8px;
    }

    .signup-btn {
        font-size: 12px;
        padding: 8px;
    }

    .signup-options {
        font-size: 10px;
    }
}