.navbar {
  display: none;
 }
 
 .badge {
   font-size: 10px;
   width: 20px;
   height: 20px;
   display: flex;
   align-items: center;
   justify-content: center;
   right: 50%;
   top: 10%; 
   transform: translate(50%, -50%);
   /* margin-top: 15px; */
   padding: 5px 0px;
 }
 
 @media only screen and (max-width: 576px){
   .navbar {
     overflow: hidden;
     background-color: var(--color-C22026);
     position: fixed;
     bottom: 0;
     width: 100%;
     z-index: 10000;
     display: flex;
     justify-content: space-around;
   }
   .navbar a {
     float: left;
     display: block;
     color: #f2f2f2;
     text-align: center;
     padding: 5px 5px;
     text-decoration: none;
     font-size: 12px;
     font-weight: 400;
     align-items: center; }
   .navbar a:hover {
     color: rgb(201, 192, 68);
   } 
 }
 .saavshgsh{
  align-items: center;
 }
 