.port_main{
    display: flex;
    flex-wrap: wrap;
}

.port_card{
    margin-top: 50px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.port_img img{
    height: 280px;
    object-fit: cover;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.port_item{
    padding: 15px;
}

.port_item h2{
    font-size: 20px;  
}

.efgetsg{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
.efgetsg span{
    color: #306fdb;
    margin-top: 10px;
}

.port_para{
    color: #000;
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.port_btn{
    display: flex;
    justify-content: space-between;
    flex-direction: row;    
    align-items: center;
    margin-top: 30px;
}

.port_btn button{
    width: 11.5rem;
    height: 2.4rem;
    font-size: .85rem;
    border: none;
    color: #fff;
    background-color: #ff00ed;
    border-radius: 5px;
    font-weight: 600;
}

.port_btn img{
    /* height: 50px;
    width: 50px; */
    border-radius: 30px;
}

.port_card_img{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
}

/* .port_card_img .image-container {
    position: relative;
    display: inline-block;
} */

/* .port_card_img .liopliop {
    display: block;
    width: 100%;
    height: auto;
} */

/* .port_card_img .image-name {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px;
    border-radius: 3px;
    font-size: 14px;
    white-space: nowrap;
} */
.port_cost{
    color: #306fdb;
    margin-top: 20px;
}
.liopliop{
    width: 2rem;
}

.port_event_cost{
    margin-top: 10px;
    font-weight: 600;
}