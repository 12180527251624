.card {
    width: 253px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    font-family: Arial, sans-serif;
}

.product-image {
    width: 100%;
    height: 160px;
    background-size: cover;
}

.card-body {
    padding: 7px;
    background-color: #fff; /* Adjust the color to match the desired blue */
    color: black;
}

.product-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
}

.product-description {
    font-size: 14px;
    margin-bottom: 16px;
}

.btn-add-to-cart {
    background-color: #673ab7; /* Yellow color for the button */
    border: none;
    padding: 8px 16px;
    color: #fff;
    font-weight: 600;
    font-size: 11px;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 26px;
    cursor: pointer;
    text-transform: uppercase;
    
}
.card-item{
    display: flex;
    justify-content: center !important;
}
 
.subCard{
    display: flex;
    justify-content: center;
    /* margin-bottom: px; */
} 

.mainCard{
    border-radius: 7px;
    height: 340px;
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-bottom: 8px;
    margin-top: 8px;
}
.mainCard img{
    overflow: hidden;
}
.card-btn {
    color: #fff;
    border: none;
    background-color: #673ab7;
    width: 100%;
    padding: 3px;
    font-size: 13px;
    font-weight: 600;
    gap: 10px;
}
.card-text{
    margin: 15px;
}
.card-cate{
    font-size: 12px;
}
.card-title{
 font-size: 13px;
 font-weight: 700;
 margin-top: 5px;
}

.card-des{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Adjust the number as needed */
    -webkit-box-orient: vertical;
    font-size: 12px;
    margin-top: 10px;
    color: #262525 !important;
    font-weight: 500;
}
.view-more-container{
    display: flex;
    justify-content: center;
    padding-top: 10px;

}

.view-more-btn{
    border: 0px;
    border-radius: 20px;
    background-color: #00cfff;
    color: #fff;
    font-size: 12px;
    padding: 10px 15px !important;
    margin: auto;
}
.view-more-btn:hover{
    color: #fff;
}
.no-products{
    display: flex;
    justify-content: center;
}
.no-products p {
    color: gray;
    font-size: 14px;
    font-weight: 500;
}

@media (max-width: 690px){
    .mainCard{
        border-radius: 7px;
        height: 250px;
        width: 200px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        margin-bottom: 8px;
        margin-top: 8px;
    }
    .card-text{
        margin: 5px;
    }
    .card-des{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* Adjust the number as needed */
        -webkit-box-orient: vertical;
        font-size: 10px;
        margin-top: 2px;
        color: #262525 !important;
        font-weight: 500;
    }
    .card-btn{
        color: #fff;
        border: none;
        background-color: #673ab7;
        width: 100%;
        padding: 3px;
        font-size: 10px;
        font-weight: 600;
    }
}