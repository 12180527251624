.payment-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.pay-amount{
  display: flex;
  justify-content: center;
  margin-top: 35px;
  margin-bottom: 10px;
}

.modal-contenti {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 10px; */
}

/* .pay-failed-icon {
  color: #fff;
  padding: 15px;
  background: #ff476b;
  border: 7px solid #feb0c0 ;
  border-radius: 50%;
} */

.modal-close {
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.modal-close:hover,
.modal-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.modal-body{
  text-align: center;
}

.modal-body h3{
  margin-top: 15px;
}

.modal-body h5 {
  font-size: 15px;
  margin-top: 15px;
}
.modal-body .pay-failed-icon{
  color: #fff;
  padding: 15px;
  background: #ff476b;
  border: 7px solid #feb0c0 ;
  border-radius: 50%;
  width: 25%;
}

.pay-success-icon{
  color: #fff;
  padding: 15px;
  background: #0e694a;
  border: 7px solid #3fb289 ;
  border-radius: 50%;
  width: 25%;
}

.pay-failed{
  margin-top: 10px;
  font-size: 24px;
}
.success-message {
  color: green;
}

.failure-message {
  color: red;
}

.pay-amount{
  font-size: 24px;
  font-weight: 600;
}
.payment-icon{
  display: flex;
  justify-content: center;
}