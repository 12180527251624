._main_footer {
    position: relative;
    box-sizing: border-box;
    /* padding-left: 90px;
    padding-top: 30px; */
    border-bottom: 1px solid #092143;
    /* padding-bottom: 21px; */
}

.footer {
    padding-bottom: 40px;
    background-color: #fff;
    position: static;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1000;
}

.component_card {
    font-family: "Lexend Deca", sans-serif;
    width: 100%;
    display: flex;
    gap: 20px;
    color: #092143;
    align-items: center;
    padding: 30px 0px 30px 0px;
}

.wd-fontsize-s {
    font-family: "Lexend Deca", sans-serif;
    font-size: 16px;
}

.wp-fontsize-p {
    font-family: "Lexend Deca", sans-serif;
    font-size: 14px;
    color: #092143;
}

.wd-sub-menu li>a {
    font-family: "Lexend Deca", sans-serif;
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #092143;
    font-size: 14px;
    line-height: 1.3;
    text-decoration: none;
    font-weight: 400;
}

ul.sub-sub-menu {
    padding: 0 !important;
}

.wd-sub-menu li>a>span {
    font-family: "Lexend Deca", sans-serif;
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #092143;
    font-size: 1rem;
    line-height: 1.3;
    font-weight: 600;
}

.mc4wp-form-fields {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-inline: -3px;
    gap: 10px;
}

p.mailchimp-input-icon {
    flex: 1 1 200px;
    max-width: 290px;
    width: 100%;
}

.footer_email{
    padding: 0 15px;
    max-width: 100%;
    width: 100%;
    height: 34px;
    /* border-radius: 35px; */
    background-color: #fff;
    color: #000;
    box-shadow: none;
    vertical-align: middle;
    font-size: 14px;
    border: 1px solid;
    border-radius: 16px;
    transition: border-color .5s ease;
    margin-top: 10px;
}

input[type="submit"] {
    border-radius: 34px;
    color: #fff;
    box-shadow: none;
    background-color: #32549B;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    font-family: "Lexend Deca", sans-serif;
    padding: 7px 15px;
    border: none;
}

._main_footer2 {
    border-bottom: 1px solid #565656;
    padding-top: 40px;
    padding-bottom: 40px;
}



._main_footer3 {
    border-bottom: 1px solid #565656;
    padding-top: 50px;
    padding-bottom: 50px;
}

._main_footer3 h5 {
    color: #092143;
    font-size: 16px;
    line-height: 26px;
    font-weight: 600;
}

.a_icons {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: #365493;
    color: #fff !important;
    text-align: center;
    padding-top: 3px;
}

.wd-social-icons {
    display: flex;
    gap: 20px;
}

.pr-2 {
    padding-right: 2rem;
}


/* Media query */

@media screen and (max-width: 600px) {
    ._main_footer {
        padding-left: 0px !important;
    }

    .component_card {
        padding-bottom: 20px;
        border-right: none !important;
    }

    .info-svg-wrapper img {
        height: 40px;
        width: 40px;
    }

    ._main_footer4 {
        padding-bottom: 50px;
    }
}