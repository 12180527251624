.category_name {
  background: none;
  font-size: 13px;
  font-weight: 600;
  padding: 15px 3px 15px 15px ;
  width: 100%;
  text-align: left;
  border: none;
  color: #363535;

}

.category_name:hover {
  border-radius: 7px;
  background: #ffa80669;
  color: rgb(53, 52, 52)
}
.active_category{
  border-radius: 7px;
  background: #ffa80669;
  color: rgb(53, 52, 52)
}

.subcate_list{
  list-style: none;
}

.sub_category_name {
  list-style: none;
  background: none;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-left: 16px;
  color: #8b8b8b;
  border-left: 1px solid;
  padding-left: 8px;
  padding-top: 7px;
  cursor: pointer;
}

.sub_category_name:hover {
  color: red;
}

.testbd {
  padding: 0px 3px;
  font-size: 14px;
  font-weight: 500;
  color: #092143;
}

.sideitemtxt {
  font-size: 15px;
  font-weight: 600;
  padding: 7px 0px;
  color: #1e1e1e70;
  margin-top: 5px;
  border-bottom: 1px solid #e9d9d9d6;
}

.colorcodeset {
  height: 10px;
  width: 10px;
}

.colorsizheight {
  height: 200px;
  overflow: scroll;
  overflow-x: hidden;
}

/* width */
::-webkit-scrollbar {
  width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f179;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.css-187mznn-MuiSlider-root {

  color: var(--color-C22026) !important;
  font-size: 10px;

}

.pricerangeset {
  font-size: 12px;
  display: flex;
  justify-content: space-around;
  /* gap: 25px; */
  color: #999494;

}

.srcdiv {
  margin-bottom: 4px;
  background: rgb(255, 255, 255);
  border: 1px solid #00000017;
}

.srcdiv:first-child {
  margin-top: 25px;
}

.srcdiv:last-child {
  margin-bottom: 25px;
  padding-bottom: 10px;
}

.reseticon {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  cursor: pointer;
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reseticon:hover {
  color: blue;
}

/* All Product List css */
.product_sort_Card {
  padding: 10px 0px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  background: #ffffff !important;
  margin-top: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.all_product_Card {
  padding: 10px 0px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  background: #ffffff !important;
}

.shop__title {
  border-bottom: 2px solid #c2202669;
  padding-bottom: 6px !important;
  /* padding-top: 1rem; */
}

.shop__title h3 {
  font-size: 16px;
}

.product_pagination_Card {
  padding: 10px 0px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  background: #ffffff !important;
}

.tab-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  gap: 15px;
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  padding-bottom: 17px;
}

.tab-menu::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Opera */
}

.tab {
  flex: 0 0 auto;
  /* Prevent tabs from wrapping */

}

.tab.active {
  /* filter: drop-shadow(0px 3px 8px rgba(19, 162, 234, 0.9)); */
  /* border: 3px solid #0f11dd; */
    border-radius: 20px;
    /* padding: 5px; */
    -webkit-filter: drop-shadow(5px 5px 5px #222 );
    filter: drop-shadow(5px 5px 5px #222);
}

.image-container {
  position: relative;
  text-align: center;
  cursor: pointer;
}

.image-container img {
  max-width: 100%;
  height: 140px;
  border-radius: 21px;
  object-fit: cover;
}

.image-container .overlay {
  position: absolute;
  top: 88%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 13px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  right: -32%;
  overflow: hidden;
}

@media (max-width: 768px) {

  .category-sidebar {
    display: none;
  }
  
  .tab-menu {
    display: flex;
    /* flex-wrap: wrap; */
  }

  .image-container img {
    max-width: 100%;
    height: 120px;
    border-radius: 21px;
    object-fit: cover;
}

  .tab {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    box-sizing: border-box;
  }

  .image-container .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 15px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
}
ul li {
  list-style-type: none;
}
.selected_subCategory{
  color: red;
}
.selected_subCategory span{
  color: red;
}
.carousel_image{
  width: 100%;
  height: 50vh;
  background-repeat: no-repeat;
  background-size: cover;
}
#captions_name{
width: 100% !important;
text-align: center;
left: 0%;
} 
.text_caption{
  text-align: center;
  font-size: 16px !important;
  margin: 20px 50px 50px 50px;


}
.slider_link{
  text-align: center;
  width: 100%;
  font-weight: 600;
  margin-bottom: 20px;
}
.service_buttons{
  width: 55%;
  text-align: center;
  display: flex;
  justify-content: space-around;
  margin: auto;
  
}