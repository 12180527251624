.category__img img{
    /* max-height: 100%; */
    /* width: 100%; */
    object-fit: cover;
    height: 83px;
}
.category_link a{
    font-size: 14px;
    color: black;
    font-weight: bold;
    padding-top: 10px;
}
.cate__cards{
    display: grid;
    grid-template-columns: repeat(6,1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
}
.cate__card{
    padding: 15px;
    background-color: white;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05)
    /* box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15); */
}

.cate__card {
    cursor: pointer;
}

.cate__card:hover {
    box-shadow: rgba(253, 11, 11, 0.2) 0px 2px 8px 0px;
}


/* .card {
    border-radius: 1px !important;
    margin: 5px;
    padding: 5px;
    outline: 0 !important;
    overflow: hidden;
    cursor: pointer;
    border: 1px solid #ebe6e6;
} */

@media only screen and (max-width: 768px) {
    .cate__cards{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 15px;
    } 
}
@media only screen and (max-width: 576px) {
    .cate__cards{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 15px;
        grid-row-gap: 15px;
    } 
}