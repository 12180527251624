.card {
    background-color: #f8f9fa;
    border: none;
    width: 100%;
}

h5 {
    font-weight: bold;
}

h3 {
    font-weight: bold;
}

@media (max-width: 768px) {
    .text-md-right {
        text-align: center !important;
    }
}

.full_head{
    font-size: 22px;
}

.full_card {
    padding: 10px 0px !important;
    margin: 0px !important;
    padding-bottom: 1rem !important;
    /* background: #D9D9D9; */
    border-bottom: 1px solid #000;
}

.full_card #image_sec {
    display: grid;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.image_main_c {
    height: 100%;
    width: 100% ;
    margin: auto;
    display: block;
    /* padding-top: 1rem; */
}

.full_card img {
    width: 80%;
    height: 80px;
    object-fit: contain;
}

.card {
    background-color: #f8f9fa;
    border: none;
    width: 100%;
}

h5 {
    font-weight: bold;
}

h3 {
    font-weight: bold;
}

@media (max-width: 768px) {
    .text-md-right {
        text-align: center !important;
    }
}

.add_technician>button{
    padding: 10px 15px; 
    border: 0px;
    background-color: #ff00ed;
    border-radius: 5px;
    color: #f8f9fa;

}
.package_list {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 10px;
}