.card-item {
    padding: 30px 0px 0px 0px;
    display: flex;
    justify-content: flex-start !important;
}

.subCard {
    display: flex;
    justify-content: center;
}

.mainCard {
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin-bottom: 8px;
    margin-top: 8px;
}

.mainCard img {
    height: 50%;
    object-fit: cover;
}

.card-btn {
    color: #fff;
    border: none;
    background-color: #673ab7;
    width: 100%;
    padding: 3px;
    font-size: 13px;
    font-weight: 600;
    gap: 10px;
}

.card-text {
    margin: 15px;
}

.card-cate {
    font-size: 12px;
}

.card-title {
    font-size: 13px;
    font-weight: 600;
    margin-top: 5px;
}

.card-price {
    font-weight: 600;
    font-size: 14px !important;
    color: #356ACB;
    font-size: 12px;
    margin-top: 13px;
}

@media (max-width: 690px) {
    .subCard {
        /* display: flex; */
        flex-wrap: wrap;
        width: 50%;
    }

    .mainCard {

        box-sizing: border-box;
    }

    #newcard {
        width: 100% !important;
    }

    #new_con {
        flex-direction: column;
    }
    #ho-button {
        transition: opacity 0.3s;
        border: none !important;
        padding: 0px;
        color: #fff;
        gap: 7px;
        font-size: 10px !important;
        text-transform: capitalize;
    }
    #ho-button1 {
        transition: opacity 0.3s;
        border: none !important;
        padding: 0px;
        color: #fff;
        gap: 07px;
        font-size: 10px !important;
        text-transform: capitalize;
    }
}

#newcard {
    position: relative;
    /* width: 25%; */
}

#ho-button {
    transition: opacity 0.3s;
    border: none !important;
    padding: 0px;
    color: #fff;
    gap: 7px;
    font-size: 14px;
    text-transform: capitalize;
}

#ho-button1 {
    transition: opacity 0.3s;
    border: none !important;
    padding: 0px;
    color: #fff;
    gap: 07px;
    font-size: 14px;
    text-transform: capitalize;
}

.product-description{
    height: 80px; /* Adjust the height as per your requirement */
    /* overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; */
}