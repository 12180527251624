.address_shipping{
    display: flex;
    justify-content: space-between;
}
.address_text{
    font-size: 14px;
    color: #5b5b5b;
}
.tbcolorst{
    background-color: #00cfff;
}