.row {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;

}

.col p {
  margin-top: 10px;
  font-weight: bold;
  color: #535353;
  font-size: 14px;
}

.tech_h2 {
  font-size: 24px;
  font-weight: 600;
  color: #ec6c23;
  line-height: 58px;
}

.new_form_control {
  border: 2px solid #000;
}

#modal_body .modal-content {
  background: #e5e2e2;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.modal_tech_p {
  color: #515151;
  font-size: 15px;
  font-weight: 500;
}

#modal_body {
  width: 50% !important;
}

@media screen and (max-width: 787px) {
  .tech_row {
    flex-direction: column !important;
  }

  .icons_tech {
    display: none !important;
  }

  #modal_body {
    width: 96% !important;
  }
  #height_tech{
    height: 60px !important;
  }
}


@media (max-width: 993px) {
  .icons_tech {
    display: none !important;
  }
  #modal_body {
    width: 96% !important;
  }
}

.reset_formData{
  border: 0px;
  border-radius: 5px;
  background-color: #FF00ED;
  color: #fff;
  padding: 6px 14px;
  margin-right: 10px;
}
.button_form{
  text-align: right;
}