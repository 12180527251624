.faqcontainer {
    max-width: 1200px; 
    margin: 0 auto; 
    padding: 0 1rem; 
  }
  
  .faqtitle {
    font-size: 2rem; 
    font-weight: bold; 
    margin-bottom: 2.5rem; 
  }
  
  .faq {
    margin-bottom: 1rem; 
  }
  
  .ques {
    font-size: 1.125;
    font-weight: bold; 
  }
  
  .ans {
    font-size: 16px; 
  }