.about_img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important; */
}

.about_text {
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
}

h6.culture{
    font-weight: 600;
}
#imagetabs{
    height: 300px;
}