.main_con {
    margin-top: -22px;
}

.kjdhfwe {
    padding: 0px;
    margin: 22px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 15px;
    border-radius: 24px !important;
}

.Heading {
    display: flex;
    margin-top: 30px;
    margin: 22px;
    padding-bottom: 15px;
}

.Heading h1 {
    color: #242424;
    font-weight: 600;
    font-size: 20px;
    padding-bottom: 5px;
    margin-bottom: -2px;
    border-bottom: 2px solid #0920e9;
}

.main_div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
}

.userlist {
    display: flex;
    align-items: center;
    margin-left: 20px;
    font-size: 22px;
    font-weight: 700;
    color: #fff;
    /* color: #0C205B; */
    /* width: 40%; */
}

.img_1 {
    display: flex;
    justify-content: center;
    height: 60px;
    width: 65px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}

.img_1 img{
    border-radius: 16px;
}
.userlistitem {
  display: flex;
  justify-content: center;
  width: 50%;
}

.userlist-item {
    display: flex;
    /* justify-content: end; */
    padding: 7px 20px 7px 20px;
    margin-left: 14px;
    background-color: #eee;
    gap: 10px;
    border-radius: 20px;
    color: #000;
    font-weight: 700;
    font-size: smaller;
    box-shadow: rgba(2555, 255, 255, 0.005) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.user_btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

.user_btn {
    border: none;
    padding: 8px 28px 8px 28px;
    border-radius: 20px;
    background-color: #ff00ed !important;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
}

.user_btn button {
    color: #fff;
}

.fgdgd {
    justify-content: space-between !important;
}

.btnhh {
    background-color: rgb(245, 65, 26);
    border: none;
    color: #fff;
}


.sfsdf {
    display: flex;
    align-items: center;
    margin-right: 32px;
    gap: 40px;
}

@media (max-width: 767.98px) {

    .kjdhfwe {
        padding: 0px;
        margin: 10px;
    }

    .userlist {
        width: 160px;
        margin-left: 7px;
        font-size: 15px;
    }

    .img_1 {
        height: 30px;
        width: 30px;
    }

    .main_div {
        height: 50px;
    }

    .userlistitem {
        display: none;
    }

    .user_btn-container {
        justify-content: center;
    }

    .user_btn {
        display: flex;
        padding: 6px 10px;

    }

    .user_btn button {
        font-size: 10px;
        font-weight: 600;
        display: flex;
        border: none;
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .jfhcffhcf {
        display: none;
    }

    .sfsdf {
        margin-right: 5px;
    }

}

div#category_accordian button{
    padding-top: 0px;
    padding-bottom: 0px;
}

div#accordian_item {
    border-radius: 0px !important;
}
/* div#accordian_item:hover{
  background: linear-gradient(180deg, rgba(127,127,129,1) 0%, rgba(170,171,173,1) 74%);

} */

.user_btn {
    box-shadow: rgba(255, 0, 237, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    position: relative;
    transition: all 0.3s ease-in-out;
    /* padding-block: 0.5rem;
    padding-inline: 1.25rem; */
    background-color: rgb(0 107 179);
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #ffff;
    gap: 10px;
    font-weight: bold;
    outline: none;
    overflow: hidden;
    font-size: 15px;
  }
  
  .user_btn:hover {
    transform: scale(1.05);
    border-color: #fff9;
  }
  
  .user_btn:hover .icon {
    transform: translate(4px);
  }
  
  .user_btn:hover::before {
    animation: shine 1.5s ease-out infinite;
  }
  
  .user_btn::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    opacity: 0.6;
  }
  
  @keyframes shine {
    0% {
      left: -100px;
    }
  
    60% {
      left: 100%;
    }
  
    to {
      left: 100%;
    }
} 

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
/* 
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
} */

.box_stucture {
  height: 100px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 1px 15px;
  margin: 22px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
}

.image_section {
  display: flex;
  align-items: center;
}

.image_structure {
  width: 65px;
  height: 60px;
  background-color: #eee;
  border-radius: 16px;
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eeeeee 0%, #dddddd 20%, #eeeeee 40%, #eeeeee 100%);
  background-size: 2000px 100%;
}

.text_title {
  height: 38px;
  width: 100px;
  background-color: #eee;
  margin-left: 20px;
  border-radius: 8px;
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eeeeee 0%, #dddddd 20%, #eeeeee 40%, #eeeeee 100%);
  background-size: 2000px 100%;
}



._s {
  height: 30px;
  width: 100px;
  background-color: #eee;
  border-radius: 8px;
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eeeeee 0%, #dddddd 20%, #eeeeee 40%, #eeeeee 100%);
  background-size: 2000px 100%;
}


._b {
  height: 20px;
  width: 20px;
  background-color: #eee;
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eeeeee 0%, #dddddd 20%, #eeeeee 40%, #eeeeee 100%);
  background-size: 2000px 100%;
}

._s {
  height: 30px;
  width: 100px;
  background-color: #eee;
  border-radius: 8px;
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eeeeee 0%, #dddddd 20%, #eeeeee 40%, #eeeeee 100%);
  background-size: 2000px 100%;
}
img.product_imge{
  width: 11%;
}
.active {
  /* background: linear-gradient(180deg, rgba(127,127,129,1) 0%, rgba(170,171,173,1) 74%); */
  border-radius: 0px;
}

#accordion-header_home{
    background: linear-gradient(to bottom, rgba(180, 181, 183, 1) 0%, rgba(120, 120, 122, 1) 100%);
}
/* .accordion-header:hover{
  background: linear-gradient(180deg, rgba(127,127,129,1) 0%, rgba(170,171,173,1) 74%);
  border-radius: 16px;

} */


.start_rental{
  background-color: #081ee9;
  color: #fff;
  padding: 15px;
  border: 0px;
  border-radius: 10px;
}
.talk_to_pro{
  background-color: #081ee9;
    color: #fff;
    padding: 15px;
    border: 0px;
      border-radius: 10px;
}
.near_slider_button{
  display: flex;
  justify-content: space-between;
  width:50%
}
.supermain_div{
  height: 100px;
  padding-top: 2%;
}
.main_accordion_text{
display: flex;
  align-items: center;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
}
#accordion_body{
  padding: 15px 0px;
}
.new_con{
  margin-left: -20px;
  margin-right: -20px;
}
      
        