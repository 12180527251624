.product__rating span i {
    color: coral;
}

.product__rating p span {
    color: coral;
}

.product__price {
    font-size: 1.3rem;
    font-weight: 500;
}

.tab__wrapper h6 {
    color: var(--primary-color);
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
}

.active__tab {
    font-weight: 600;
}

.tab__content p {
    line-height: 30px;
}

.review__wrapper ul li span {
    color: coral;
    font-weight: 600;
}

.review__wrapper ul li p {
    margin-top: 10px;
}

.reviw__form {
    width: 70%;
    margin: auto;
    margin-top: 50px;
}

.reviw__form h4 {
    font-size: 1.2rem;
    font-size: 600;
    margin-bottom: 30px;
}

.from__group input,
.from__group textarea {
    width: 100%;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    padding: 8px 20px;
}

.from__group input:focus,
.from__group textarea:focus {
    outline: none;
}

.from__group {
    margin-bottom: 30px;
}

.from__group span {
    display: flex;
    color: coral;
    align-items: center;
    column-gap: 5px;
    font-weight: 600;
}

.related__title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 30px;
}

.product__img {
    /* height: 319px;
    width: 319px; */
    width: 100%;
    height: 100%;
}

.imgdiv {
    display: flex;
    justify-content: center;

}
.product_img_main{
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    
}

.product_item_imgdiv {
    margin: 15px 12px 10px 10px;
}

.spinner-container{
    position: absolute;
    top: 50%;
    left: 23%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.react-multiple-carousel__arrow {
    color: red;
    position: absolute;
    top: 51%;
    z-index: 2;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background: #fff0 !important;
    box-shadow: none;
    border-radius: 50em;
    border: 0;
    font-size: 15px;
    line-height: 40px;
    text-align: center;
}

.sizecolor_div {
    display: flex;
    gap: 12px;
    align-items: center;

    margin-bottom: 20px;
    margin-top: 20px;
}

.input__button__div {
    display: flex;
    align-items: center;
}

.input__button__div input {
    width: 23px;
    border: none;
    text-align: center;
}

.itemBtn__st {
    font-size: 30px;
    color: #d6d3d3;
    cursor: pointer;
}

.itemBtn__st:hover {
    font-size: 30px;
    color: #fdc8c8;
    cursor: pointer;
}

.payabletxt {
    font-size: 23px;
    font-weight: 600;
    color: #ffc800;
}

.sizediv {
    display: flex;
    gap: 10px;
}

.productitem {
    border-radius: 6px;
    height: 100px;
    width: 100px;
    object-fit: cover;
    cursor: pointer;
    transition: 0.3s ease;
}

.productitem:hover{
    transform: scale(1.1);
}
.sizedata {
    border: 1px solid #dfdada;
    padding: 0px 8px;
    font-size: 13px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.sizedata:hover {
    background: red;
    color: white
}

.nametxt {
    width: 111px;
}

.buyNow {
    background: #ffb603 !important;
}

.activecolorst {
    border: 2px solid #ffb603;
    cursor: pointer;
}

.btninner {
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 15px;
}

@media only screen and (max-width: 768px) {

    .product__details h2 {
        font-size: 1.3rem;
    }

    .rating__group {column-gap: 1rem !important;
    }
    .btn_dev{
        padding: 10px 10px 10px 10px !important;
    }

    .productitem {
        border-radius: 6px;
        height: 70px;
        width: 70px;
        object-fit: cover;
        cursor: pointer;
        transition: 0.3s ease;
        margin-right: 8px;
    }
    .productitem:hover{
        transform: scale(1.1);
    }
    .product_item_imgdiv {
        margin: 8px 8px 8px 8px;
        padding: 4px 0px 4px 5px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch !important;
    }
}

.product_des {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
}

.product_detail {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 8rem;
    width: 100%;
    margin-top: 25px;
}

.product_price h3{
    font-size: 28px;
    font-weight: 600;
}

.product_price p {
    font-size: 14px;
    color: #306fdb;
}

.product_devli {
    text-align: end;
}

.btn_dev {
    background-color: #ff00ed;
    color: #fff;
    border: none;
    padding: 10px 28px 10px 23px;
    gap: 10px;
    margin: .5rem;
    border-radius: 4px;
    gap: 10px;
}
.btn_dev_icon{
    margin-right: 5px;
    font-size: 20px;
}

.btn_pick {
    background-color: #dcdcdc;
    color: #000;
    border: none;
    padding: 10px 20px 10px 17px;
    gap: 10px;
    margin: .5rem;
    border-radius: 4px;
}

.btn_pick_icon {
    margin-right: 1rem;
    font-size: 20px;
}

.product_info {
    display: flex;
    align-items: baseline;
    margin-top: 20px;
}

.product_info_icon {
    font-size: 24px;
}

.product_inculde {
    color: #000;
    font-size: 18px;
    font-weight: 300;
    margin-left: 10px;
}

.product-item-link {
    width: 100%;
}

.product-list {
    list-style: none;
    padding: 0;
}



.product-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: .2rem 1rem;
    border-radius: 5px;
}

.product-image {
    /* width: 50px; */
    /* height: 50px; */
    margin-right: 1rem;
}

.product_item_info {
    display: flex;
    align-items: center;
}

.product-name {
    color: #000;
    font-size: 18px;
    font-weight: 500;
}

.product-quantity {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.show-more-button {
    cursor: pointer;
    color: #306fdb;
}

.tab-panel {
    margin-top: 40px;
}
.tab-content{
    padding: 10px;
}

.tab-title-icon{
    margin-right: 7px;
}
.image_logo_positions{
    position: relative;
}

 
.image_logo{

    width: 66%;
    position: absolute;
    bottom: 35px;
    right: -42px;
    /* width: 50%;
    position: absolute;
    bottom: 0px;
    right: 0px; */
}

.inner_cantact{
    display: flex;
    background-color: #00cfff;
    padding: 10px 10px;
    align-items: center;
}
.user_images_contact{
    width: 13%;
    border-radius: 100%;
}
.conatc_heading{
    color: #000;
    font-weight: 600;
    line-height: 2.1;
}
.contact_sub_heading{
    font-weight: 300;
    line-height: 2.1;
}
.information_sections{
    margin-left: 35px;
}
.contact_number{
    line-height: 2.1;
}
.product_heading{
    font-size: 17px;
    font-weight: 600;
    padding:10px 0px;
}
.comments_input{
    padding: 7px;
    width: 81%;
}
.comment_form{
    margin-top: 20px;
}
.comments_list p{
    color: #000;
    font-size: 15px;
    font-weight: 500;
    padding:10px;
    border: 1px solid #bbb;
    border-radius: 10px;
    margin: 10px 0px;
}

.aske_question{
    width: 100%;
    padding: 8px 8px;
    margin: 10px 0px;
    border: none;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
background: rgba(255, 255, 255, 0.17);
border-radius: 5px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5.7px);
-webkit-backdrop-filter: blur(5.7px);
border: 1px solid rgba(255, 255, 255, 1);
    
}
.ans_qes{
    border: 1px solid #f0f0f0;
    padding: 18px 24px;
    line-height: 2;
}
.answer_type{
    font-size: 14px;
    color: #787878;
}
.question_type{
    font-size: 15px;
    font-weight: 400;
    color: #000;
}
.user_name{
    font-size: 13px;
    color: #787878;
    border-radius: 20px;
    font-weight: 600;
    display: inline-block;
}

.rate {
    float: left;
    /* height: 46px; */
    padding: 0 10px;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:55px;
    color:#ccc;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #969595;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #969595;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #969595;
}
.showmore{
    border: none;
    font-size: 14px;
    color: #00cfff;
    background-color: transparent;
}
.user_feedback{
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0px;
}